<template>
  <div class="allmsgclass">
    <navigation msg="萝卜猎手人脸实名认证"></navigation>
    <div class="heng"></div>
    <div class="s8">
      <div class="s1">为保证您的信息安全</div>
      <div class="s2">请进行人脸识别认证</div>
    </div>

    <div class="s6">
      <div class="s3">
        <img src="../../assets/facestart_1.png" alt="" />
        <div>
          <div class="s4">确保本人操作</div>
          <div class="s5">非本人操作将无法通过认证</div>
        </div>
      </div>
      <div class="s3">
        <img src="../../assets/facestart_2.png" alt="" />
        <div>
          <div class="s4">识别光线适中</div>
          <div class="s5">请保证光线不要过暗或过亮</div>
        </div>
      </div>
      <div class="s3">
        <img src="../../assets/facestart_3.png" alt="" />
        <div>
          <div class="s4">正面对准手机</div>
          <div class="s5">保持您的脸出现在取景框内</div>
        </div>
      </div>
    </div>
    <div class="s7">
      <div class="rgclass" @click="toren">开始身份认证</div>
      <div class="xieyiall">
        <van-checkbox
          checked-color="#FF6D20"
          icon-size=".31rem"
          v-model="checked"
          shape="square"
          >阅读并同意<span class="xieyi"
            >《实名认证用户隐私协议》</span
          ></van-checkbox
        >
      </div>
    </div>

    <div style=".3rem"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: false,
    };
  },
  methods:{
      toren(){
          if(!this.checked){
              this.$toast("请勾选协议");
              return;
          }
          this.$router.push("/faceupload");
      }
  }
};
</script>
<style scoped>
.xieyiall {
  font-size: 0.28rem;
  font-weight: 500;
  color: #616b80;
  line-height: 0.33rem;
  display: flex;
  justify-content: center;
  margin-top: 0.24rem;
}
.xieyiall .xieyi {
  color: #ff6d20;
}
.rgclass {
  text-align: center;
  background: linear-gradient(90deg, #ff5f18 0%, #ff8d35 100%);
  border-radius: 0.42rem;
  padding: 0.18rem 0;
  font-size: 0.28rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.48rem;
  margin: 0 0.8rem;
}
.s7 {
  position: absolute;
  width: 100%;
  bottom: 0.5rem;
}
.s6 {
  margin: 9vh 0;
}
.s5 {
  font-size: 0.24rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.34rem;
  margin-top: 0.08rem;
}
.s4 {
  font-size: 0.28rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.4rem;
}
.s3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
}
.s3 img {
  width: 1.14rem;
  margin-right: 0.34rem;
}
.s1 {
  font-size: 0.32rem;
  font-weight: 800;
  color: #303133;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 0.22rem;
}
.s2 {
  font-size: 0.44rem;
  font-weight: 800;
  color: #303133;
  line-height: 0.48rem;
  text-align: center;
}
.heng {
  height: 1px;
  background-color: #eeeeee;
}
.allmsgclass {
  min-height: 100%;
  position: relative;
  background-color: white;
}
</style>